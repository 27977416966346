interface IImage {
  URL: string;
  description: string;
}

interface ISource {
  URL: string;
  siteName: string;
}

interface IPost {
  images: IImage[];
  text: string;
  date: number;
  dateCreated: number;
  dateUpdated: number;
  tags: string[];
  source: ISource[];
  toolsAndTechnology: string[];
}

export class Post {
  public images: IImage[];
  public text: string;
  public date: number;
  public dateCreated: number;
  public dateUpdated: number;
  public tags: string[];
  public source: ISource[];
  public toolsAndTechnology: string[];

  public post: IPost;

  constructor(post: IPost) {
    Object.assign(this, post);
  }
}
