import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.css']
})
export class WipComponent implements OnInit {
  public showWarning = true;
  constructor() {}

  ngOnInit() {}

  public hideWarning() {
    this.showWarning = false;
  }
}
