import { MainService } from './../services/main.service';
import { PortfolioService } from './../services/portfolio.service';
import { Post } from './../models/post.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  private unsubscribeSubject: Subject<boolean> = new Subject<boolean>();
  private portfolio: Post[];
  // private allPostsForCurrentTag: Post[];
  public filteredPosts: Post[];
  private selectedTag: string;
  public pageNumber = 1;
  private amountOfPages = 1;
  public selectedPost: Post;

  constructor(private portfolioService: PortfolioService, private mainService: MainService) {}

  ngOnInit() {
    this.portfolioService.portfolio.subscribe(p => {
      this.portfolio = p;
    });
    this.mainService.selectedTag.pipe(takeUntil(this.unsubscribeSubject)).subscribe(t => {
      this.selectedTag = t;
      this.filteredPosts = this.filterPosts(t);
    });
    this.mainService.pageNumber.pipe(takeUntil(this.unsubscribeSubject)).subscribe(x => {
      this.pageNumber = x;
      this.filteredPosts = this.filterPosts(this.selectedTag);
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.unsubscribe();
  }

  public getFirstImage(post: Post): string {
    return 'url(' + post.images[0].URL + ')';
  }

  private filterPosts(tag: string): Post[] {
    this.amountOfPages = 1;
    let array: Post[] = [];
    if (this.portfolio != null) {
      if (tag === 'all' || tag === '') {
        array = this.portfolio;
      } else {
        this.portfolio.forEach(p => {
          if (p.tags.includes(tag)) {
            array.push(p);
          }
        });
      }
    }
    // this.allPostsForCurrentTag = array;
    this.amountOfPages = Math.ceil(array.length / 8);
    if (array.length > 8) {
      const begin = (this.pageNumber - 1) * 8;
      const end = this.pageNumber * 8;
      const partOfArray = array.slice(begin, end);
      array = partOfArray;
      // console.log('amount of pages', this.amountOfPages);
    }
    return array;
  }

  public changePage(pageNumber: number): void {
    if (this.amountOfPages > 1) {
      if (this.pageNumber >= 1 && pageNumber !== -1) {
        const x = this.pageNumber + pageNumber;
        // this.pageNumber = x;
        this.mainService.changePageNumber(x);
      } else {
        // this.pageNumber = 1;
        this.mainService.changePageNumber(1);
      }
    }
    if (this.pageNumber < 1) {
      console.error('ERROR Error: Pagenumber went below 1, this is an error.');
    }
    this.filteredPosts = this.filterPosts(this.selectedTag);
  }

  public selectPost(post: Post): void {
    this.mainService.selectPost(post);
  }

  public setFadein(i: number): string {
    return 'fadein' + i;
  }

  public checkIfButtonDisabled(direction: string): string {
    if (direction === 'previous' && this.pageNumber === 1) {
      return 'disabled-arrow';
    }
    if (direction === 'next' && this.pageNumber === this.amountOfPages) {
      return 'disabled-arrow';
    }
    return 'active-arrow';
  }
}
