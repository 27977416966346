// tslint:disable:max-line-length
import { Post } from './../models/post.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  private portfolioSource = new BehaviorSubject<Post[]>([null]);
  public portfolio = this.portfolioSource.asObservable();
  public data = [
    // Data will be moved to and imported from a separate .JSON file once it is finalized.
    // Anna 3D model, UPDATE LATER
    // {
    //   images: [
    //     {
    //       URL:
    //         'https://cdn.discordapp.com/attachments/169050890911744000/456500544664633345/Screenshot_2018-06-13_18.49.37.png',
    //       description: 'Anna model sheet for 3D modelling.'
    //     },
    //     {
    //       URL:
    //         'https://cdn.discordapp.com/attachments/417670551629201430/456796472973983755/Screenshot_2018-06-13_23.04.40.png',
    //       description: 'Work in progress mesh preview of the 3D Anna model.'
    //     }
    //   ],
    //   text: 'Personal work, progress of a 3D model intended to be 3D printed.',
    //   date: 65464784,
    //   dateCreated: 65464784,
    //   dateUpdated: 65464784,
    //   tags: ['3D', 'illustration'],
    //   toolsAndTechnology: ['FDM printing', 'Autodesk Maya', 'Adobe Photoshop'],
    //   source: [
    //     {
    //       URL: 'http://siebedraws.tumblr.com',
    //       siteName: 'tumblr'
    //     }
    //   ]
    // },
    // Gizma
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/89a4592bca0552acb0166900141a010e/tumblr_p8kgmcAWZj1s8y94wo1_1280.png',
          description: 'Gnome character design for a Dungeons & Dragons campaign'
        },
        {
          URL: 'https://78.media.tumblr.com/653e900ec665b39de472163e17750a3c/tumblr_p8kgmcAWZj1s8y94wo2_1280.png',
          description: 'Top-down tokens for use in an online Dungeons & Dragons campaign'
        }
      ],
      text:
        'Personal work, character design work and tokens to visualise these designs in a digital boardgame environment',
      date: 1525996800,
      dateCreated: 1525996800,
      dateUpdated: 1525996800,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/173801030236/while-ive-been-dumping-art-anyway-heres-some',
          siteName: 'tumblr'
        }
      ]
    },
    // Aria
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/da1bae293cdd4bd213ad8ebb35fe4f45/tumblr_p8itgqHqKD1s8y94wo1_1280.png',
          description: 'Tiefling character design for Dungeons & Dragons'
        }
      ],
      text: 'Aria, tiefling character design for Dungeons & Dragons. Personal work.',
      date: 1493628730,
      dateCreated: 1493628730,
      dateUpdated: 1493628730,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Illustrator'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/173769637530/speaking-of-old-art-heres-something-from-more',
          siteName: 'tumblr'
        }
      ]
    },
    // Loti feeding fish
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/22bebed777125522e30aa51f3d961373/tumblr_p08swlBV2f1s8y94wo1_1280.png',
          description: 'Axolotl character design, "Loti"'
        }
      ],
      text: 'Character design and illustration, personal work.',
      date: 1512000000,
      dateCreated: 1512000000,
      dateUpdated: 1512000000,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/168050259665/its-nice-and-quiet-down-here',
          siteName: 'tumblr'
        },
        {
          URL: 'https://twitter.com/Siebedraws/status/936319515377455104',
          siteName: 'twitter'
        }
      ]
    },
    // Anna redesign character sheet
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/3f6d4cd522a541dad543d27d95eae13c/tumblr_oew24zunoe1tbflbto1_1280.png',
          description: 'Original Anna reference page'
        },
        {
          URL:
            'https://firebasestorage.googleapis.com/v0/b/siebes-character-creator.appspot.com/o/files%2Fgraphics%2Fplaceholder.png?alt=media&token=e5170867-0163-458f-a796-83a6a9845d6b',
          description: 'text and stuff'
        }
      ],
      text: 'Test post with a picture of Anna and a placeholder image.',
      date: 1468195200,
      dateCreated: 1468195200,
      dateUpdated: 1468195200,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/',
          siteName: 'tumblr'
        }
      ]
    },
    // Ribbon & Nona Birthday gift for Balooga
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/7d842de6680e0838d73dbb83ffeb47d4/tumblr_p143o9nl9T1s8y94wo1_1280.png',
          description: 'Nona and Ribbon as Fantasy RPG characters.'
        }
      ],
      text:
        'Gift for a friend, Nona (blue hair) being an original character design and Ribbon (black hair) being his design.',
      date: 1513468800,
      dateCreated: 1513468800,
      dateUpdated: 1513468800,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/168643066720/happy-birthday-balooga',
          siteName: 'tumblr'
        },
        {
          URL: 'https://twitter.com/Siebedraws/status/942420872756940800',
          siteName: 'twitter'
        }
      ]
    },
    // Loti with crab
    {
      images: [
        {
          URL: 'https://66.media.tumblr.com/627a578cfe31f17616d701e1e8c535df/tumblr_pjd3j1wHbn1ruc0xho1_1280.png',
          description: 'Loti caught a stowaway hitching a ride.'
        }
      ],
      text: 'Character illustration of Loti, an axolotl-inspired character design. Personal work.',
      date: 1510185600,
      dateCreated: 1510185600,
      dateUpdated: 1510185600,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/167314440135/caught-a-stowaway-hitching-a-ride',
          siteName: 'tumblr'
        },
        {
          URL: 'https://twitter.com/Siebedraws/status/928726852734242817',
          siteName: 'twitter'
        }
      ]
    },
    // Airi from inktober
    {
      images: [
        {
          URL: 'https://pbs.twimg.com/media/DMhPO6AW4AE-00Y.jpg',
          description: 'Entry for day 15 of inktober 2017, Airi'
        }
      ],
      text:
        '15th entry for inktober 2017, an annual event where artists attempt to draw one thing in ink every day during october. My entries in 2017 had all been digitally inked.',
      date: 1508371200,
      dateCreated: 1508371200,
      dateUpdated: 1508371200,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/166576183305/inktober-15-this-old-drawing-made-a-sudden',
          siteName: 'tumblr'
        },
        {
          URL: 'http://twitter.com/siebedraws',
          siteName: 'twitter'
        }
      ]
    },
    // bomb robot
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/f1851920ff1775d48f1f3ec13e271db0/tumblr_ozkrvqGPTj1s8y94wo1_1280.png',
          description: 'Bomberman-inspired robot character design.'
        }
      ],
      text: 'Original robot design, somewhat inspired by Bomberman Generations.',
      date: 1510876800,
      dateCreated: 1510876800,
      dateUpdated: 1510876800,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/167595872720/revisiting-that-robot-design-from-inktober',
          siteName: 'tumblr'
        },
        {
          URL: 'https://twitter.com/Siebedraws/status/931593357125709826',
          siteName: 'twitter'
        }
      ]
    },
    // Fall inktober drawings with Ribbon, Nona and Enna.
    {
      images: [
        {
          URL: 'https://78.media.tumblr.com/0d0176ccc2e17cf9c5a8d62ed4f39f4c/tumblr_oxbeszPlNn1s8y94wo1_1280.png',
          description: 'The three images combined.'
        },
        {
          URL: 'https://78.media.tumblr.com/274815280fd505a684ddb37117ecef9b/tumblr_ox7smwOe531s8y94wo1_1280.png',
          description: 'Second entry for inktober 2017, on its own.'
        },
        {
          URL: 'https://78.media.tumblr.com/98351748b764ab14eb1b5aec7257ba7c/tumblr_ox9moslaPE1s8y94wo1_1280.png',
          description: 'Third entry for inktober 2017, on its own.'
        },
        {
          URL: 'https://78.media.tumblr.com/540f80b665bc0221355b2ac9197a68d6/tumblr_oxbdazGzgK1s8y94wo1_1280.png',
          description: 'Fourth entry for inktober 2017, on its own.'
        }
      ],
      text: 'My 2nd, 3rd and 4th entries of inktober 2017, combined into a single image.',
      date: 1507075200,
      dateCreated: 1507075200,
      dateUpdated: 1507075200,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/166050742565/inktober-days-2-3-4-all-together-now',
          siteName: 'tumblr'
        }
      ]
    },
    {
      images: [
        {
          URL: 'https://66.media.tumblr.com/c8f745d73e7575482b6303c3ede7ec93/tumblr_phr0q18StB1s8y94wo2_r1_1280.png',
          description: 'Personalized ad for a Discord Server, "Shift".'
        },
        {
          URL: 'https://66.media.tumblr.com/7314ea5d30c80bbd2a16c25f60603e8f/tumblr_phspc6eQm81s8y94wo2_1280.png',
          description: 'Preliminary design sketch, for outfit and props.'
        }
      ],
      text: 'Personalised ad for the "Shift" Discord server.',
      date: 1541534400,
      dateCreated: 1541534400,
      dateUpdated: 1541534400,
      tags: ['illustration'],
      toolsAndTechnology: ['Adobe Photoshop'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/179838484165/join-the-shift-discord-server-this-is-the-discord',
          siteName: 'tumblr'
        }
      ]
    },
    // brightlight posters
    {
      images: [
        {
          URL: 'https://66.media.tumblr.com/fae18af46e29e1e69b6681476adedd19/tumblr_n6lnllseIt1s8y94wo1_1280.png',
          description: 'Red variation poster for Brightlight'
        },
        {
          URL: 'https://66.media.tumblr.com/b1078d9f55b154e14057db9a38b3d20d/tumblr_n6lnllseIt1s8y94wo2_1280.png',
          description: 'Green variation posted for Brightlight'
        },
        {
          URL: 'https://66.media.tumblr.com/e2ca7119351f525f0a2965bf240e5b65/tumblr_n6lnllseIt1s8y94wo3_1280.png',
          description: 'Blue variation posted for Brightlight'
        }
      ],
      text: 'Graphical posters for the unfinished Brightlight game, personal project.',
      date: 1404471884,
      dateCreated: 1404471884,
      dateUpdated: 1404471884,
      tags: ['graphic design', 'illustration'],
      toolsAndTechnology: ['Adobe Illustrator'],
      source: [
        {
          URL: 'http://siebedraws.tumblr.com/post/87701823145/school-assignment-thing-posters-for-my-game',
          siteName: 'tumblr'
        }
      ]

    }
  ];

  // still to be added.
  // http://siebedraws.tumblr.com/post/166142655965/inktober-6-rainy-mari
  // http://siebedraws.tumblr.com/post/165439390970/lava-lord-nona-new-unlockable-skin
  // http://siebedraws.tumblr.com/post/160050543856/psst-i-also-have-a-twitter-for-sketches-doodles
  // http://siebedraws.tumblr.com/post/149674570975/also-this-doodle-of-nona-enna-mostly-cuz-i-was
  // http://siebedraws.tumblr.com/post/147906567755/did-some-doodles

  constructor() {
    const sortedData = this.sortData();
    const data: Post[] = [];
    sortedData.forEach(item => {
      data.push(new Post(item));
    });
    this.setPortfolio(data);
  }

  private sortData() {
    const returnValue = this.data.sort(this.compare);
    return returnValue;
  }

  private compare(a: Post, b: Post) {
    if (a.dateCreated > b.dateCreated) {
      return -1;
    }
    if (a.dateCreated < b.dateCreated) {
      return 1;
    }
    return 0;
  }

  private setPortfolio(portfolio: Post[]) {
    this.portfolioSource.next(portfolio);
  }
}
