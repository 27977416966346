export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyB0P9FiyTbZRz1GYqFHEBgSYst5iYt08LA',
    authDomain: 'siebe-portfolio.firebaseapp.com',
    databaseURL: 'https://siebe-portfolio.firebaseio.com',
    projectId: 'siebe-portfolio',
    storageBucket: 'siebe-portfolio.appspot.com',
    messagingSenderId: '128994282272'
  }
};
