import { Post } from './../models/post.model';
/* tslint:disable:max-line-length*/
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private tagsSource = new BehaviorSubject<string[]>(['all', '3D', 'graphic design', 'illustration', 'development']);
  public tags = this.tagsSource.asObservable();

  private selectedTagSource = new BehaviorSubject<string>('all');
  public selectedTag = this.selectedTagSource.asObservable();

  private selectedPostSource = new BehaviorSubject<Post>(null);
  public selectedPost = this.selectedPostSource.asObservable();

  private pageNumberSource = new BehaviorSubject<number>(1);
  public pageNumber = this.pageNumberSource.asObservable();

  constructor() {}

  public selectTag(tag: string): void {
    this.selectedTagSource.next(tag);
  }

  public selectPost(post: Post): void {
    this.selectedPostSource.next(post);
  }

  public changePageNumber(x: number): void {
    this.pageNumberSource.next(x);
  }
}
