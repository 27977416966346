import { MainService } from './../services/main.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.mainService.selectTag('');
    this.mainService.selectPost(null);
  }
}
