import { Post } from './models/post.model';
import { MainService } from './services/main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribeSubject: Subject<boolean> = new Subject<boolean>();
  public selectedPost: Post;

  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.mainService.selectedPost.pipe(takeUntil(this.unsubscribeSubject)).subscribe(p => {
      this.selectedPost = p;
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.unsubscribe();
  }
}
