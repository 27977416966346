import { MainService } from './../services/main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Post } from '../models/post.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit, OnDestroy {
  private unsubscribeSubject: Subject<boolean> = new Subject<boolean>();
  public post: Post;

  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.mainService.selectedPost.pipe(takeUntil(this.unsubscribeSubject)).subscribe(p => {
      this.post = p;
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.unsubscribe();
  }

  public hidePost(): void {
    this.mainService.selectPost(null);
  }

  public selectPost(post: Post): void {
    this.mainService.selectPost(null);
  }

  public selectTag(tag: string): void {
    this.mainService.selectTag(tag);
    this.mainService.changePageNumber(1);
    this.mainService.selectPost(null);
  }

  public getImageSource(name: string): string {
    switch (name) {
      case 'twitter':
        return '../../assets/twitterlogo.png';
      case 'tumblr':
        return '../../assets/tumblrlogo.png';
      default:
        return '../../assets/websitelogo.png';
    }
  }
}
