import { Router } from '@angular/router';
import { Post } from './../models/post.model';
import { MainService } from './../services/main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubscribeSubject: Subject<boolean> = new Subject<boolean>();
  public tags: string[];
  private selectedTag: string;

  public dropdownToggle = false;

  constructor(private main: MainService, private router: Router) {}

  ngOnInit() {
    this.main.tags.pipe(takeUntil(this.unsubscribeSubject)).subscribe(tags => {
      this.tags = tags;
    });
    this.main.selectedTag.pipe(takeUntil(this.unsubscribeSubject)).subscribe(tag => {
      this.selectedTag = tag;
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next(true);
    this.unsubscribeSubject.unsubscribe();
  }

  public getID(tag): string {
    return 'id' + tag.replace(/\s/g, '');
  }

  public selectPost(post: Post): void {
    this.main.selectPost(post);
  }

  public selectTag(tag): void {
    this.main.selectTag(tag);
    this.main.changePageNumber(1);
    this.main.selectPost(null);
    this.dropdownToggle = false;
  }

  public checkIfTagIsSelected(tag: string): string {
    if (tag === this.selectedTag) {
      return 'selected ' + this.getID(tag);
    } else {
      return '';
    }
  }

  public checkIfDropdownTagIsSelected(tag: string): string {
    if (tag === this.selectedTag) {
      if (tag === 'all') {
        return 'inset-shadow grey-bg';
      } else {
        return 'inset-shadow ' + this.getID(tag);
      }
    }
    return '';
  }

  public checkOpacity(s: string): string {
    const route: string = '/' + s;
    if (route === this.router.url) {
      return 'current-page';
    } else {
      return '';
    }
  }

  public toggleDropdown(): void {
    this.dropdownToggle = !this.dropdownToggle;
  }

  public showOrHideDropdown(): string {
    if (this.dropdownToggle) {
      return 'drop-down-open';
    } else {
      return 'drop-down-closed';
    }
  }

  public checkIfMenuIsOpen(): string {
    if (this.dropdownToggle) {
      return 'svg-arrow';
    }
  }
}
