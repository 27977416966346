import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.mainService.selectTag('');
    this.mainService.selectPost(null);
  }
}
